<template>
  <Diagonal>
    <div class="blackout-font foo">
      <div class="u2 content-center">
        <span class="u1">{{ $t("welcome") }}</span>

        <div class=" mt-8 text-lg font-mono">
          {{ $t('about2') }}
        </div>
        <div>
          <p>
            <button class="btn btn-primary" @click="hejja">sadfg</button>
          </p>
        </div>
      </div>
    </div>
  </Diagonal>
</template>

<script lang="ts" setup>

definePageMeta({
  layout: 'expandmenulayout'
})
useSeoMeta({
  title: '',
  description: '[description]',
  ogTitle: '[og:title]',
  ogDescription: '[og:description]',
  ogImage: '[og:image]',
  ogUrl: '[og:url]',
  twitterTitle: '[twitter:title]',
  twitterDescription: '[twitter:description]',
  twitterImage: '[twitter:image]',
  twitterCard: 'app'
})

useHead({
  link: [
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon.ico'
    }
  ]
})
const localePath = useLocalePath()

async function hejja() {
  const { data } = await useFetch('/api/hello');

  console.log(data.value);
}

</script>

<style></style>






